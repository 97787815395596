<template>
  <v-container fluid>
    <page-header title="Formulário de avaliação"></page-header>
    <card-loading-transparent v-if="loading"></card-loading-transparent>
    <div v-if="!loading">
      <show-form :course="course" exec_time="rating"></show-form>
    </div>
  </v-container>
</template>

<script>
  import Api from '@/api/index';

  export default {
    name:'CourseRating',
    components: {
      ShowForm: () => import('../forms/show/ShowForm.vue'),
    },
    data() {
      return {
        course: {},
        loading: false
      }
    },
    methods: {
      getCourse(){
        this.loading = true
        Api.Course.show(this.$route.params.course_id).then(r=>r.data).then(d=>{
          this.course = d
          setTimeout(() => {
            this.loading = false
          }, 1200);
        })
      }
    },
    mounted () {
      this.getCourse()
    },
  }
</script>

<style lang="scss" scoped>

</style>